<template>
  <b-card>
    <g-form @click="print">
      <b-row>
        <!-- from Date  -->
        <b-col md="6">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>

        <!-- to Date  -->
        <b-col md="6">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col>

        <!-- transaction type  -->
        <b-col md="8">
          <b-form-group>
            <g-field
              label-text="transactionType"
              field="select"
              name="transactionType"
              multiple
              required
              :options="taxTransactions"
              :label="isRight ? 'arabicName' : 'englishName'"
              :value.sync="selectedTransaction"
              :dir="isRight ? 'rtl' : 'ltr'"
            />
          </b-form-group>
        </b-col>

        <!-- is taxable -->
        <b-col md="2">
          <label
            style="font-size: 14px; margin-bottom: 7px"
            for="isStudentTaxable"
          >
            <feather-icon
              v-b-tooltip.hover="$t('taxableStudentsHint')"
              icon="InfoIcon"
              class="mx-25 clickable"
            />
            {{ $t("taxableStudents") }}
          </label>
          <b-form-group>
            <b-form-checkbox
              v-model="selectedItem.isStudentTaxable"
              class="mr-0 mt-50"
              name="isStudentTaxable"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="profile.permissions.indexOf('viewShowAllBranchesInReports') > -1 || profile.isAdmin"
          md="2"
        >
          <label
            style="font-size: 14px; margin-bottom: 13px"
            for="ShowAllBranches"
          >
            {{ $t("ShowAllBranches") }}
          </label>
          <b-form-group>
            <b-form-checkbox
              v-model="selectedItem.ShowAllBranches"
              class="custom-control-primary"
              name="ShowAllBranches"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- review button  -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            class="mx-1"
            variant="relief-primary"
            @click="print('taxPreview')"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            {{ $t('review') }}
          </b-button>
          <b-button
            variant="outline-primary"
            @click="print('taxReturn')"
          >
            <feather-icon
              icon="TrendingUpIcon"
              class="mr-50"
            />
            {{ $t('taxReturn') }}
          </b-button>
        </b-col>
      </b-row>

    </g-form>
  </b-card>
</template>

<script>
import { transactionTypes, reportParameters } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';

export default {
   mixins: [
    reportMixin,
  ],

  data() {
    return {
      selectedTransaction: [],
      taxTransactions: transactionTypes,
      selectedItem: {
        transactionsName: '',
        salesInvoiceIncluded: false,
        salesReturnIncluded: false,
        studentDiscountIncluded: false,
        expenseIncluded: false,
        voucherIncluded: false,
        fromDate: '',
        toDate: ''
      }
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
    this.setDefault();
  },

  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
    setDefault() {
      // set default transaction
      if (this.taxTransactions.length > 0) {
        this.selectedTransaction.push(this.taxTransactions[0]);
      }

      // set default value for taxes situation according to student
      this.selectedItem.isStudentTaxable = true;
    },

    validateForPrint() {
      if (this.selectedTransaction.length === 0) {
        this.doneAlert({ text: this.$t('mustSelectatLeastOneTransaction'), type: 'warning' });
        return false;
      }
      return true;
    },

    getSelectedTransactionsSTR() {
      let result = ''
      if (this.selectedTransaction.length === this.taxTransactions.length) {
        result = this.$t('allTransactions')
      } else {
        for (let i = 0; i < this.selectedTransaction.length; i++) {
          result = result.concat(this.isRight ? this.selectedTransaction[i].arabicName : this.selectedTransaction[i].englishName);
          if (i < this.selectedTransaction.length - 1) {
            result = result.concat(' ، ');
          }
        }
      }
      return result
    },

    prepareForPrint() {
      // determine if all transaction selected or specfic ones selected
      this.selectedItem.transactionsName = this.getSelectedTransactionsSTR();

      // then identify which transaction is included
      this.selectedItem.salesInvoiceIncluded = this.selectedTransaction.find((transaction) => transaction.id === 'sale') !== undefined;
      this.selectedItem.salesReturnIncluded = this.selectedTransaction.find((transaction) => transaction.id === 'return') !== undefined;
      this.selectedItem.studentDiscountIncluded = this.selectedTransaction.find((transaction) => transaction.id === 'discount') !== undefined;
      this.selectedItem.expenseIncluded = this.selectedTransaction.find((transaction) => transaction.id === 'expense') !== undefined;
      this.selectedItem.voucherIncluded = this.selectedTransaction.find((transaction) => transaction.id === 'voucher') !== undefined;
      const allbranches = reportParameters.find((par) => par.id === 'allBranches');
      this.selectedItem.branchName = this.selectedItem.ShowAllBranches
                                    ? this.isRight ? allbranches.arabicName : allbranches.englishName
                                    : this.isRight ? this.currentBranch.arabicName : this.currentBranch.englishName
    },

    getSelectedReport(actionName) {
      switch (actionName) {
        case 'taxPreview':
          return this.isRight ? 'Taxes-ar' : 'Taxes-en'

        case 'taxReturn':
          return this.isRight ? 'TaxesTotal-ar' : 'TaxesTotal-en'

        default:
          return null
      }
    },

    print(type) {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      if (type === 'taxPreview' && !this.validateForPrint()) return;
      this.prepareForPrint();
      const reportName = this.getSelectedReport(type)
      this.printReport(reportName, this.selectedItem);
    }
  },
}
</script>
